.container ol li {
  font-size: 1.4em;
  color: #cecece;
}

.start {
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.start .btn {
  padding: 0.2em 1.7em;
  border: none;
  border-radius: 0.1em;
  font-size: 1.2em;
  color: #202020;
  text-decoration: none;
  background-color: #faff5a;
}

#form {
  display: flex;
  justify-content: center;
  margin-top: 4em;
}

#form .userid {
  padding: 0.7em 2em;
  width: 50%;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
